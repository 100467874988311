import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Save, Play } from 'lucide-react';
import { Button } from "./ui/button";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "./ui/select";
import { Input } from "./ui/input";
import { Slider } from "./ui/slider";
import { Card, CardHeader, CardContent } from "./ui/card";
import { Label } from "./ui/label";
import apiClient from '../apiClient';
import ErrorBoundary from './ErrorBoundary';

const ProfileSettings = () => {
  const [settings, setSettings] = useState(null);
  const [previewWord, setPreviewWord] = useState({ word: 'Hello', definition: 'Привіт' });

  const fetchUserSettings = useCallback(async () => {
    // console.log('Fetching user settings..');
    try {
      const response = await apiClient.get('/settings/user_settings');
      console.log('User settings received:', response.data);
      setSettings(response.data);
    } catch (error) {
      console.error('Error fetching user settings:', error);
    }
  }, []);

  useEffect(() => {
    fetchUserSettings();
  }, [fetchUserSettings]);


  const saveSettings = async () => {
    if (!settings) return;
    try {
      await apiClient.post('/settings/user_settings', settings);
      alert('Settings saved successfully!');
    } catch (error) {
      console.error('Error saving user settings:', error);
      alert('Failed to save settings. Please try again.');
    }
  };

  const handleVoiceChange = (index, value) => {
    if (!settings) return;
    const newVoicePattern = [...settings.voicePattern];
    newVoicePattern[index].voice = value;
    setSettings({ ...settings, voicePattern: newVoicePattern });
  };

  const handleTypeChange = (index, value) => {
    if (!settings) return;
    const newVoicePattern = [...settings.voicePattern];
    newVoicePattern[index].type = value;
    setSettings({ ...settings, voicePattern: newVoicePattern });
  };

  const handleVoiceSettingChange = (voiceKey, value) => {
    if (!settings) return;
    setSettings({
      ...settings,
      voices: { ...settings.voices, [voiceKey]: value },
    });
  };

  const playPreview = async () => {
    if (!settings) return;
    for (const pattern of settings.voicePattern) {
      const text = pattern.type === 'newWord' ? previewWord.word : previewWord.definition;
      const voice = settings.voices[pattern.voice];
      
      try {
        const response = await apiClient.post('/language/text_to_speech', { text, voice }, { responseType: 'blob' });
        const audioUrl = URL.createObjectURL(response.data);
        const audio = new Audio(audioUrl);
        audio.play();
        await new Promise(resolve => audio.onended = resolve);
        await new Promise(resolve => setTimeout(resolve, settings.delayBetweenWords));
      } catch (error) {
        console.error('Error playing audio:', error);
      }
    }
    await new Promise(resolve => setTimeout(resolve, settings.additionalDelay));
  };

  return (
    <ErrorBoundary>
      <Card className="w-full max-w-4xl mx-auto mt-8">
        <CardHeader>
            <h2 className="text-2xl font-bold">Profile Settings</h2>
        </CardHeader>
        <CardContent>
            {settings ? (
              <div className="space-y-6">
                <div>
                    <h3 className="text-lg font-semibold mb-2">Voice Pattern</h3>
                    {settings.voicePattern.map((pattern, index) => (
                    <div key={index} className="flex space-x-2 mb-2">
                        <Select value={pattern.type} onValueChange={(value) => handleTypeChange(index, value)}>
                        <SelectTrigger>
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="newWord">New Word</SelectItem>
                            <SelectItem value="definition">Definition</SelectItem>
                        </SelectContent>
                        </Select>
                        <Select value={pattern.voice} onValueChange={(value) => handleVoiceChange(index, value)}>
                        <SelectTrigger>
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="voice1">Voice 1</SelectItem>
                            <SelectItem value="voice2">Voice 2</SelectItem>
                            <SelectItem value="voice3">Voice 3</SelectItem>
                            <SelectItem value="native">Native</SelectItem>
                        </SelectContent>
                        </Select>
                    </div>
                    ))}
                </div>

                <div>
                    <h3 className="text-lg font-semibold mb-2">Voice Settings</h3>
                    {Object.entries(settings.voices).map(([key, value]) => (
                    <div key={key} className="flex items-center space-x-2 mb-2">
                        <Label htmlFor={`voice-${key}`} className="w-20">{key}:</Label>
                        <Input
                        id={`voice-${key}`}
                        value={value}
                        onChange={(e) => handleVoiceSettingChange(key, e.target.value)}
                        placeholder={`Enter ${key} voice ID`}
                        />
                    </div>
                    ))}
                </div>

                <div>
                    <h3 className="text-lg font-semibold mb-2">Timing</h3>
                    <div className="space-y-4">
                    <div>
                        <Label htmlFor="delay-between-words">Delay Between Words: {settings.delayBetweenWords}ms</Label>
                        <Slider
                        id="delay-between-words"
                        value={[settings.delayBetweenWords]}
                        onValueChange={([value]) => setSettings({ ...settings, delayBetweenWords: value })}
                        max={3000}
                        step={100}
                        />
                    </div>
                    <div>
                        <Label htmlFor="additional-delay">Additional Delay: {settings.additionalDelay}ms</Label>
                        <Slider
                        id="additional-delay"
                        value={[settings.additionalDelay]}
                        onValueChange={([value]) => setSettings({ ...settings, additionalDelay: value })}
                        max={5000}
                        step={100}
                        />
                    </div>
                    </div>
                </div>

                <div>
                    <h3 className="text-lg font-semibold mb-2">Preview</h3>
                    <div className="flex space-x-2 mb-2">
                    <Input
                        value={previewWord.word}
                        onChange={(e) => setPreviewWord({ ...previewWord, word: e.target.value })}
                        placeholder="Enter a word"
                    />
                    <Input
                        value={previewWord.definition}
                        onChange={(e) => setPreviewWord({ ...previewWord, definition: e.target.value })}
                        placeholder="Enter the definition"
                    />
                    <Button onClick={playPreview} className="flex items-center">
                        <Play className="w-4 h-4 mr-2" />
                        Play
                    </Button>
                    </div>
                </div>

                <Button onClick={saveSettings} className="w-full">
                    <Save className="w-4 h-4 mr-2" />
                    Save Settings
                </Button>
              </div>
            ) : (
                <div className="text-center py-8">
                    <span>Loading settings...</span>
                </div>
            )}
        </CardContent>
      </Card>
    </ErrorBoundary>
  );
};

export default ProfileSettings;
