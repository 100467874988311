import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function SignUp() {
  const [email, setEmail] = useState('');
  const { loginWithRedirect } = useAuth0();

  const handleSubmit = (e) => {
    e.preventDefault();
    loginWithRedirect({
      screen_hint: 'signup',
      login_hint: email
    });
  };

  return (
    <div>
      <h2>Sign Up</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
        />
        <button type="submit">Sign Up</button>
      </form>
      <button onClick={() => loginWithRedirect({ screen_hint: 'signup' })}>
        Sign Up with Social Account
      </button>
    </div>
  );
}

export default SignUp;