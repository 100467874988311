import React from 'react';
import { LogOut, LogIn, BookOpen, Settings, Home } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

function Header() {
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const location = useLocation();

    const isProfileSettings = location.pathname === '/profile-settings';
  
    return (
      <header className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-6 shadow-lg">
        <div className="container mx-auto px-4">
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <div className="text-center sm:text-left mb-4 sm:mb-0">
              <h1 className="text-4xl sm:text-5xl font-bold">Language Learner</h1>
              <p className="text-xl sm:text-2xl mt-1 text-blue-100">by Activated Human</p>
            </div>
            <div className="flex space-x-4">
              {isAuthenticated && (
                isProfileSettings ? (
                  <Link to="/" className="btn btn-glass">
                    <Home className="w-5 h-5 mr-2" />
                    Home
                  </Link>
                ) : (
                  <Link to="/profile-settings" className="btn btn-glass">
                    <Settings className="w-5 h-5 mr-2" />
                    Settings
                  </Link>
                )
              )}
              {isAuthenticated ? (
                <button
                  onClick={() => logout({ returnTo: window.location.origin })}
                  className="btn btn-glass"
                >
                  <LogOut className="w-5 h-5 mr-2" />
                  Log Out
                </button>
              ) : (
                <button onClick={() => loginWithRedirect()} className="btn btn-glass">
                  <LogIn className="w-5 h-5 mr-2" />
                  Log In
                </button>
              )}
            </div>
          </div>
        </div>
      </header>
    );
  }

export default Header;