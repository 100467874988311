import React from 'react';
import { Link } from 'react-router-dom';

function AuthorizedPage() {
    return (
      <div className="container mx-auto text-center py-16">
        <h2 className="text-3xl font-semibold mb-8">You are authorized!</h2>
        <p className="text-lg">Welcome to the authorized section of the app.</p>
        <Link to="/language-learning" className="mt-4 inline-block bg-blue-600 text-white py-2 px-4 rounded-full">
          Go to Language Learning
        </Link>
      </div>
    );
  }
  
export default AuthorizedPage;
  