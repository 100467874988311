import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';


function LoginPage() {
    const { isAuthenticated, loginWithRedirect } = useAuth0();
  
    if (isAuthenticated) {
      return <Navigate to="/authorized" />;
    }
  
    return (
      <div className="container mx-auto text-center py-16">
        <h2 className="text-3xl font-semibold mb-8">Please Log In</h2>
        <button onClick={() => loginWithRedirect()} className="bg-blue-600 text-white py-2 px-4 rounded-full">
          Log In
        </button>
      </div>
    );
  }
  
export default LoginPage;
